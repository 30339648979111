import {
  GetOptionFlowQuery,
  GetFreeOptionFlowQuery,
  GetTickerOptionFlowQuery,
  GetFullFeedTickerOptionFlowQuery,
  GetHistoricalOptionFlowQuery,
  GetHistoricalOptionFlowWithTickersQuery,
  GetTopTickersQuery,
  GetDarkPoolTradesQuery,
  GetFreeTickerOptionFlowQuery,
} from 'types/generated/client';

export const BULLISH = 25;
export const SLIGHTLY_BULLISH = 45;
export const SLIGHTLY_BEARISH = 55;
export const BEARISH = 75;
export const HIGH_VOLUME_TRADE_PERCENT = 0.3;

export enum MobileLayout {
  Cards = 'CARDS',
  Table = 'TABLE',
}

export enum Sentiment {
  Bullish = 'BULLISH',
  Bearish = 'BEARISH',
  None = 'NONE',
}

export enum TradeDirection {
  Ask = 'A',
  AboveAsk = 'AA',
  Mid = 'M',
  Bid = 'B',
  BelowBid = 'BB',
}

interface DerivedTradeValues {
  heatScore: number;
  scoreWidth: number;
  time: string;
  timeSubscript: string;
  expiry?: number;
  expiryString: string;
  daysToExpiry: number;
  isUnusual: boolean;
  isTopPosition: boolean;
  isGoldenSweep: boolean;
  tradeDirection?: TradeDirection;
  createdTimestamp: number;
}

export type OptionFlowTrade = GetOptionFlowQuery['option_flow'][0] & DerivedTradeValues;
export type TickerFlowTrade = GetTickerOptionFlowQuery['ticker_option_flow'][0] &
  DerivedTradeValues;
export type TopTickersFlowTrade = GetTopTickersQuery['top_tickers'][0]['firstTrades'][0] &
  DerivedTradeValues;
export type HistoricalOptionFlow = GetHistoricalOptionFlowQuery['option_feed_filtered'][0] &
  DerivedTradeValues;
export type HistoricalOptionFlowWithTickers =
  GetHistoricalOptionFlowWithTickersQuery['option_feed_filtered'][0] & DerivedTradeValues;

export type DailyVolume = GetOptionFlowQuery['daily_volume'][0];
export type RawTrade =
  | GetOptionFlowQuery['option_flow'][0]
  | GetFreeOptionFlowQuery['free_option_flow'][0]
  | GetTickerOptionFlowQuery['ticker_option_flow'][0]
  | GetFreeTickerOptionFlowQuery['free_ticker_option_flow'][0]
  | GetFullFeedTickerOptionFlowQuery['full_feed_option_flow'][0]
  | GetHistoricalOptionFlowQuery['option_feed_filtered'][0]
  | GetHistoricalOptionFlowWithTickersQuery['option_feed_filtered'][0]
  | GetTopTickersQuery['top_tickers'][0]['firstTrades'][0];
export type CombinedTrade =
  | OptionFlowTrade
  | TickerFlowTrade
  | HistoricalOptionFlow
  | HistoricalOptionFlowWithTickers
  | TopTickersFlowTrade;

export type Trade = Omit<CombinedTrade, '__typename'>;

export enum CallPut {
  Call = 'Call',
  Put = 'Put',
}

export enum TradeSide {
  Bid = 'BID',
  Ask = 'ASK',
  Mid = 'MID',
  None = 'NONE',
}

export enum OrderType {
  Block = 'BLOCK',
  Sweep = 'SWEEP',
  Split = 'SPLIT',
}

export const DAYS_PER_YEAR = 365;

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE',
}

export enum ColumnType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Date = 'DATE',
}

export interface FlowTableHeaderProps {
  flowSortKey: string;
  setFlowSortKey: (key: string) => void;
  flowSortDirection: SortDirection;
  setFlowSortDirection: (direction: SortDirection) => void;
  isWatchListOnly: boolean;
  setIsWatchListOnly: (isWatchListOnly: boolean) => void;
  hasWatchListItems: boolean;
  isSoundEnabled: boolean;
  setIsSoundEnabled: (isSoundEnabled: boolean) => void;
  mobileLayout?: MobileLayout;
  setMobileLayout?: (mobileLayout: MobileLayout) => void;
  data?: FullTrade[];
  hasAccess: boolean;
}

interface FlowDetails {
  heatScore: number;
  scoreWidth: number;
  time: string;
  timeSubscript: string;
  expiry?: number;
  expiryString: string;
  daysToExpiry: number;
}

export type FullTrade = Trade & FlowDetails & { isBlur?: boolean };
export interface FlowTableRowProps {
  trade: FullTrade;
  watchListTickers: string[];
  trackRowAsAnimated?: () => void;
  hasBeenAnimated?: boolean;
}

export interface FlowTableProps extends FlowTableHeaderProps {
  trades: FullTrade[];
  watchListTickers: string[];
  totalFlowCount?: number;
}

export enum TopTickerKeys {
  UnusualOTM = 'UNUSUAL_OTM',
  Momentum = 'MOMENTUM',
  SweepTrades = 'SWEEP_TRADES',
  ImpliedVol = 'IMPLIED_VOl',
  TopPositions = 'TOP_POSITIONS',
}

export enum SignaturePrintStatus {
  None = 'NONE',
  Buy = 'BUY',
  Sell = 'SELL',
}

export interface EquityPrintOverview {
  open: number;
  high: number;
  low: number;
  lastPrice: number;
  avgPrice: number;
}

export type EquityPrint = GetDarkPoolTradesQuery['equity_print_flow'][0];
